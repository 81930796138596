import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import { BrowserRouter, Route, Routes } from "react-router-dom";


function App() {
  return (
    <div>
      <BrowserRouter>

        <Navbar></Navbar>
        <Routes>

          <Route path="/" element={<Home></Home>} />


        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
